import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Card, Button } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import topwave from "../../content/assets/top-wave.svg"

class Diving extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Diving at Capernwray" />
        <Card className="text-white text-center opacity-9 rounded-0">
          <StaticImage
            className="mh-100 page-hero"
            src="../../content/assets/diving.jpg"
          />
          <Card.ImgOverlay>
            <Card.Title
              as="h1"
              className="pt-4 page-title card-hero-title text-uppercase font-weight-bold"
            >
              Diving at Capernwray
            </Card.Title>
            <div className="chevron-container">
              <a href="#membership">
                <div className="chevron-down"></div>
              </a>
            </div>
          </Card.ImgOverlay>
        </Card>
        <div id="membership" className="topwave-container">
          <img src={topwave} className="topwave" />
        </div>
        <Container>
          <Row>
            <Col sm={12}>
              <Card className="text-center m-3">
                <Card.Header>
                  <h2>Diving Membership</h2>
                </Card.Header>
                <Card.Body>
                  <Card.Title>£40</Card.Title>
                  <Card.Text>2 year membership</Card.Text>
                  <Card.Text>Access to facilities</Card.Text>
                  <Button
                    href="https://cprnwry.typeform.com/to/xOE6hT"
                    variant="primary"
                  >
                    Register
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={6}>
              <Card className="text-center m-3">
                <Card.Header>
                  <h2>Diving Entry</h2>
                </Card.Header>
                <Card.Body>
                  <Card.Title>£18</Card.Title>
                  <Card.Text>Access to facilities</Card.Text>
                  <Card.Text>To be paid on entry</Card.Text>
                  <Card.Text>Check in at the gate or Reception</Card.Text>
                  <Button variant="primary" disabled>
                    Prepay <i>(coming soon)</i>
                  </Button>
                </Card.Body>
                <Card.Footer className="text-primary">
                  Membership holders only
                </Card.Footer>
              </Card>
            </Col>
            <Col sm={6}>
              <Card className="text-center m-3">
                <Card.Header>
                  <h2>Diving Day Pass</h2>
                </Card.Header>
                <Card.Body>
                  <Card.Title>£27</Card.Title>
                  <Card.Text>
                    Discounted to <b>£23</b> if booked online
                  </Card.Text>
                  <Card.Text>Access to facilities</Card.Text>
                  <Card.Text>To be paid online in advance</Card.Text>
                  <Card.Text>Check in at the gate or Reception</Card.Text>
                  <Button
                    href="https://cprnwry.typeform.com/to/am9tBc"
                    variant="warning"
                  >
                    Book a Day Pass*
                  </Button>
                  <Card.Text>
                    *Please check opening times before booking a daypass.
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="text-warning">
                  For non-members
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Diving

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
